import { gql } from 'urql';

export const portfolioOverviewGlobalMatching = gql`
query portfolioKPIsQuery($startDate: DateTime!, $endDate: DateTime!, $consumerIds: [UUID!], $producerIds: [UUID!], $technologies: [Technology!], $matchingResolution: MatchingResolution!, $maxDuration: Duration) {
  portfolioKpi(startTime: $startDate, endTime: $endDate, consumerUuids: $consumerIds, productionDeviceIds: $producerIds, technologies: $technologies, matchingResolution: $matchingResolution, maxDuration: $maxDuration) {
    overviewGlobalMatching {
      unit,
      data {
        consumption,
        matchingGenerationTotal,
        matchingGenerationAllocated,
        percentageMatchingGenerationAllocated,
        percentageMatchingGenerationUnallocated,
        matchingGenerationUnallocated,
        unmatchedConsumption,
        excessGenerationTotal,
        excessGenerationUnallocated,
        excessGenerationAllocated,
        generation,
        generationAllocated,
        generationUnallocated,
      }
    }
  }
}`;

export const portfolioOverTimeMatching = gql`
query portfolioKPIsQuery($startDate: DateTime!, $endDate: DateTime!, $consumerIds: [UUID!], $producerIds: [UUID!], $technologies: [Technology!], $matchingResolution: MatchingResolution!, $maxDuration: Duration) {
  portfolioKpi(startTime: $startDate, endTime: $endDate, consumerUuids: $consumerIds, productionDeviceIds: $producerIds, technologies: $technologies, matchingResolution: $matchingResolution, maxDuration: $maxDuration) {
    overTimeMatching {
      unit,
      interval,
      data {
        date
        consumption,
        matchingGenerationAllocated,
        matchingGenerationUnallocated,
        unmatchedConsumption,
        excessGenerationUnallocated,
        excessGenerationAllocated
      }
    }
  }
}`;

export const portfolioLongRunAverageMatching = gql`
query portfolioKPIsQuery($startDate: DateTime!, $endDate: DateTime!, $consumerIds: [UUID!], $producerIds: [UUID!], $technologies: [Technology!], $matchingResolution: MatchingResolution!, $maxDuration: Duration) {
  portfolioKpi(startTime: $startDate, endTime: $endDate, consumerUuids: $consumerIds, productionDeviceIds: $producerIds, technologies: $technologies, matchingResolution: $matchingResolution, maxDuration: $maxDuration) {
    longRunAverageMatching {
      unit,
      timeAttribute,
      data {
        timeIndex,
        consumption,
        matchingGenerationAllocated,
        matchingGenerationUnallocated,
        unmatchedConsumption,
        excessGenerationUnallocated,
        excessGenerationAllocated
      }
    }
  }
}`;
