import { type Durations, type MatchingResolution, durationEnum, matchingResolutionEnum } from '~/schemas/enums';

const granularitiesToMatchingResolutionsMap: Map<Durations, MatchingResolution> = new Map([
  [durationEnum.enum.ALL, matchingResolutionEnum.enum.HOURLY],
  [durationEnum.enum.PT5M, matchingResolutionEnum.enum.FIVE_MINUTE],
  [durationEnum.enum.PT15M, matchingResolutionEnum.enum.FIFTEEN_MINUTE],
  [durationEnum.enum.PT30M, matchingResolutionEnum.enum.THIRTY_MINUTE],
  [durationEnum.enum.PT1H, matchingResolutionEnum.enum.HOURLY],
  [durationEnum.enum.P1D, matchingResolutionEnum.enum.DAILY],
  [durationEnum.enum.P1M, matchingResolutionEnum.enum.MONTHLY],
]);

const matchingResolutions = [
  matchingResolutionEnum.enum.FIVE_MINUTE,
  matchingResolutionEnum.enum.FIFTEEN_MINUTE,
  matchingResolutionEnum.enum.THIRTY_MINUTE,
  matchingResolutionEnum.enum.HOURLY,
  matchingResolutionEnum.enum.DAILY,
  matchingResolutionEnum.enum.MONTHLY,
  matchingResolutionEnum.enum.QUARTERLY,
  matchingResolutionEnum.enum.YEARLY,
  matchingResolutionEnum.enum.YEARLY_UK_COMPLIANCE_PERIOD,
  matchingResolutionEnum.enum.WHOLE_PERIOD,
];

export const durationToMatchingResolution = (duration: Durations) =>
  granularitiesToMatchingResolutionsMap.get(duration) as MatchingResolution;

export const durationsToMatchingResolutions = (durations: Durations[] | undefined) => {
  if (!durations) {
    return [];
  }
  return durations.map((g) => durationToMatchingResolution(g)).filter((g) => g) as MatchingResolution[];
};

export const getCoarsestMatchingResolutions = (matchingResolution: MatchingResolution) => {
  const coarsestMatchingResolutionIndex = matchingResolutions.indexOf(matchingResolution);
  return matchingResolutions.slice(coarsestMatchingResolutionIndex);
};

export const getCoarserMatchingResolutions = (durations: Durations[] | undefined) => {
  if (!durations || durations.length === 0) {
    return [];
  }

  // Convert durations to matching resolutions
  const matchingResolutionSet = new Set(durationsToMatchingResolutions(durations));

  // Find the finest matching resolutions
  const finestMatchingResolution = matchingResolutions.find((td) => matchingResolutionSet.has(td));

  if (!finestMatchingResolution) {
    return [];
  }

  // Get the index of the finest matching resolution
  const startIndex = matchingResolutions.indexOf(finestMatchingResolution);

  // Return all matching resolutions from the finest onwards
  return matchingResolutions.slice(startIndex);
};

export const getApplicableMatchingResolutionsFromMaxDuration = (maxDuration: Durations): MatchingResolution[] => {
  if (maxDuration === durationEnum.enum.ALL) {
    return matchingResolutions;
  }

  const matchingResolution = durationToMatchingResolution(maxDuration);

  const index = matchingResolutions.indexOf(matchingResolution);
  if (index === -1) {
    return [];
  }

  return matchingResolutions.slice(index);
};
