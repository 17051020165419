import { z } from 'zod';
import { matchingResolutionEnumLower } from './enums';

const supplierOrganizationConfigSchema = z.object({
  name: z.string(),
  country_code: z.string().length(2),
  logo_url: z.string().url().nullable(),
  default_timezone: z.string(),
  matching_resolutions: z.array(matchingResolutionEnumLower).nullable(),
  hide_production_devices_from_reports: z.boolean(),
});

const endConsumerOrganizationConfigSchema = z.object({
  name: z.string(),
  welcome_screen_enabled: z.boolean(),
  hide_production_devices: z.boolean(),
});

export const userConfigSchema = z.object({
  supplier: supplierOrganizationConfigSchema,
  end_consumer: endConsumerOrganizationConfigSchema.nullable(),
  feature_flags: z.object({}).catchall(z.union([z.boolean(), z.string(), z.number()])),
});

export type UserConfigApiResponse = z.infer<typeof userConfigSchema>;
